export const ServiceType = {
  AVITO: 'avito',
  GOOGLE: 'google',
  MEDIAPLATFORM: 'mediaplatform',
  METRICAPRO: 'metricapro',
  MYTARGET: 'mytarget',
  PROMOPAGES: 'promopages',
  TELEGRAM: 'telegram',
  VKADS: 'vkads',
  VKMARKET: 'vkmarket',
  VKONTAKTE: 'vkontakte',
  YANDEX: 'yandex',
  YANDEXBUSINESS: 'yandexbusiness',
}

export const ServiceName =  {
  [ServiceType.AVITO]: "Авито",
  [ServiceType.GOOGLE]: "Google Ads",
  [ServiceType.MEDIAPLATFORM]: "Медиаплощадки",
  [ServiceType.METRICAPRO]: "Метрика Про",
  [ServiceType.MYTARGET]: "myTarget",
  [ServiceType.PROMOPAGES]: "Яндекс ПромоСтраница",
  [ServiceType.TELEGRAM]: "Telegram",
  [ServiceType.VKADS]: "VK Реклама",
  [ServiceType.VKMARKET]: "VK Маркет",
  [ServiceType.VKONTAKTE]: "ВКонтакте",
  [ServiceType.YANDEX]: "Яндекс Директ",
  [ServiceType.YANDEXBUSINESS]: "Яндекс Бизнес"
}

